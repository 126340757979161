export default {
  formSteps: {
    0: {
      question: 'Che cosa preferiresti fare?',
      choices: {
        0: 'Qualche giorno di vacanza spontanea senza un piano fisso.',
        1: 'Cucinare insieme agli amici più stretti e poter conversare liberamente con loro.',
        2: 'Organizzare una festa a sorpresa per una persona cara.',
        3: 'Passare un pomeriggio tranquillo nella propria casa con giardino.',
        4: 'Cercare il brivido attraverso uno sport rischioso.',
      },
      advices: {
        0: '<p><strong>La tua capacità nr. 1: Indipendenza</strong><br>Le persone con questa capacità non si tirano indietro davanti a uno stile di vita autonomo con decisioni indipendenti. Non<strong>lasciarsi facilmente portare</strong> fuori pista, quando si presentano nuove situazioni – per questo ci vogliono forza interiore e coraggio. E queste due capacità le possiedi evidentemente.<br>Forse il pensiero di avere un figlio ancora ti spaventa. Anche perchè forse ti preoccupi, di perdere un pò della tua autodeterminazione.<br>Allo stesso tempo, l\' indipendenza spesso include la capacità di <strong>non lasciarsi</strong>limitare dalle sfide e dalle nuove situazioni. Anzi questa libertà la puoi sfruttare <strong>per essere orgogliosa delle decisioni</strong> prese in passato.</p>',
        1: '<p><strong>La tua capacità nr. 1: Empatia</strong><br>Con questa capacità senti probabilmente in maniera sensibile, cosa succede nelle altre persone e cosa tocca il cuore altrui. Con la tua <strong>empatia</strong> puoi anche offrire sicurezza e amore. Perchè sai come e cosa fare in modo che gli altri si sentano consolati e accolti con calore.<br>Forse momentaneamente con la gravidanza hai difficoltà a interpretare quello che si agita dentro di te. È possibile che tu abbia paura e che non ti senta all altezza di questa sfida.<br>Ma prorio tu con la tua sensibilità puoi intuire,<strong> ciò di cui hai bisogno,</strong> quando ti si presentano situazioni difficili</p>',
        2: '<p><strong>La tua capacità nr. 1: Elan o slancio</strong><br>Quando tu afferri una cosa, allora sì che le cose procedono – ti dedichi con passione alle persone e ai compiti. Così ti riesce pure di suscitare il coraggio negli altri e far procedere le cose.<br>Forse momentaneamente ti preoccupa il fatto che le sfide della gravidanza potrebbero soffocare il "fuoco" in te e che la tua vita perda la sua pienezza..<br>Contemporaneamente possiedi prorio tu con la tua<strong>forza di volontà</strong> la capacità adatta, per <strong>crescere da questa situazione.</strong></p>',
        3: '<p><strong>La tua capacità nr. 1: Equilibrio</strong><br>Come persona che apprezza la calma, probabilmente puoi anche avere un effetto equilibrante sulle altre persone. Grazie alla tua prudenza e tranquillità, anche gli altri intorno a te possono trovare la calma.<br>Forse momentaneamente ti preoccupi, che le sfide di questa gravidanza ti facciano perdere la tua sicurezza interiore.<br>Contemporaneamente potresti forse sfruttare la tua capacità proprio per affrontare, <strong>nel modo più tranquillo possibile,</strong> la situazione e <strong>fare con cautela un passo dopo l\'altro.</strong> In questo modo, il tuo percorso personale, nel quale puoi crescere oltre i tuoi limiti, si apre sempre di più.</p>',
        4: '<p><strong>La tua capacità nr. 1: Coraggio</strong><br>Tu ami l\'avventura, la sfida, l\'ignoto – Vai volenterosa ai tuoi limiti. Per questo sei disposta a prendere su di te un sacco di cose e a <strong>lasciare la tua zona di comfort o zona comoda.</strong> Così sei anche <strong>un\'ispirazione per gli altri,</strong> per spronarli a osare di fare cose nuove.<br>Forse sei preoccupata, a causa della tua gravidanza, di dover rinunciare ad alcune delle tue esperienze e di perdere alcune delle cose che fanno di te ciò che sei. ..<br>Allo stesso tempo, hai esperienza nel superare ostacoli apparentemente insormontabili. Fa parte della tua natura non lasciarti distrarre da tali sfide – ma <strong>affrontare la tua paura.</strong> Così trasformi anche le difficoltà in avventure eccitanti.</p>',
      },
    },
    1: {
      question: 'Hai appena saputo che, per motivi di lavoro, ti devi trasferire in un\'altra città - qual è al tua prima reazione ?',
      choices: {
        0: 'Mi viene subito in mente, quello che che devo fare per prima cosa: disdire l\'appartamento vecchio, cercarne uno nuovo, organizzare il trasloco ecc.',
        1: 'La notizia mi proccupa molto a livello emotivo. All\'inizio ho difficoltà a pensare con chiarezza.',
        2: 'Cerco di affrontare la nuova situazione in modo costruttivo - anche se devo prima "digerire" la notizia.',
      },
      advices: {
        0: '<p><strong>La tua capacità nr. 2: Forza della ragione</strong><br>È una tua capacità ragionare con cura, soppesare tutte le possibilità e cercare buone soluzioni – questo è un talento prezioso!<br>Tu usi la tua ragione, per superare situazioni difficili ed escogitare passi concreti che ti portino fino alla tua meta. Non lasci che le avversità ti prendano di sorpresa, ma mantieni il <strong>sangue freddo.</strong> Così possiedi la capacità di <strong>risolvere bene anche problemi molto complessi.</strong></p>',
        1: '<p><strong>La tua capacità nr. 2: Intuizione</strong><br>Tu hai una buona intuizione ed un "fiuto" sensibile. Il tuo istinto punta spontaneamente in una certa direzione e ti mostra una prima tendenza.</p>',
        2: '<p><strong>La tua capacità nr. 2: Stabilità</strong><br>In situazioni difficili presti attenzione <strong>con sensibilità e prudenza</strong> alla ragione e all\' intuizione. Questa stabilità è una buona base per prendere decisioni mature!<br>Da una parte usi la ragione per cercare delle buone soluzioni ai diversi problemi e per programmare i prossimi passi. Dall\'altra rimani aperta alla tua voce interiore ed alla direzione che essa ti mostra.</p>',
      },
    },
    2: {
      question: 'Se nella mia vita avessi più tempo, desidererei...',
      choices: {
        0: 'passare più tempo con i miei cari',
        1: 'investire più tempo nei miei progetti preferiti e svilupparmi ulteriormente',
        2: 'intraprendere più cose belle nel mio tempo libero',
        3: 'essere più consapevole per rimanere fedele a me stessa',
      },
      advices: {
        0: '<p><strong>La tua capacità nr. 3: Relazionarsi</strong><br>Non vivi per te stessa, ma anche per gli altri. Ti si confà profondamente l\'essere aperta per le esigenze altrui e dividere con loro la tua vita. Attraverso ciò sperimenti un profondo appagamento.<br>Forse hai anche già fatto l\'esperienza, che prendersi cura degli altri, dà un <strong>senso profondo</strong> nella vita. Perchè sperimenti una gioia piena, quando distogli <strong>lo sguardo da te stessa</strong> e ti impegni per gli altri.</p>',
        1: '<p><strong>La tua capacità nr. 3: Motivazione</strong><br>Ti piace adoperare le tue capacità per qualcosa. In questo modo, plasmi i progetti in cui sei coinvolta, attraverso il tuo operare può nascere <strong>qualcosa di  significativo</strong>. Essere assorbita da una buona causa ti appaga.<br>Perciò forse questo ti è familiare: provi una profonda soddisfazione quando affronti situazioni difficili <strong>ed operi per una causa più grande.</strong></p>',
        2: '<p><strong>La tua capacità nr. 3: Entusiasmo</strong><br>La vita è bella - e tu ami gustare di questa bellezza. Essa la scopri in tutti i posti possibili ed immaginabili: nella natura, nella creatività artistica o nel conoscere altre culture. Questa <strong>visione ampia al di là di te stessa</strong> e nelle particolarità del mondo ti dà un profondo appagamento.<br> Perciò forse questo ti è familiare: anche in situazioni difficili sviluppi il bello e  trovi così una piena soddisfazione.</p>',
        3: '<p><strong>La tua capacità nr. 3: Autenticità</strong><br>Ti appaga guardarti alo specchio ed essere orgogliosa di quello che sei e quello che fai. È evidente che sei una <strong>persona autentica,</strong> che ha <strong>valori chiari</strong>. Pertanto sei anche pronta a difendere le tue convinzioni in modo diretto.<br>Perciò forse questo ti è familiare: provi appagamento nelle situazioni difficili e cresci oltre te stessa quando rimani fedele a te stessa.</p>',
      },
    },
    3: {
      question: 'Chi sveglieresti più facilmente nel mezzo della notte quando hai grandi preoccupazioni e hai bisogno di qualcuno con cui parlare?',
      choices: {
        0: 'Il mio partner',
        1: 'Mia mamma o mio papà',
        2: 'Uno dei miei fratelli o un mio parente stretto',
        3: 'Qualcuno della mia cerchia di amici',
        4: 'Non conosco nessuno con cui oserei farlo',
      },
      advices: {
        0: '<p><strong>La tua capacità nr. 4: Partnership</strong><br> Hai un partner al tuo fianco con cui puoi condividere le gioie e le preoccupazioni. Che sostegno prezioso! Insieme è più facile superare le difficoltà e trarre il meglio da tutto ciò. Voi infatti potete <strong>sotenervi e consolarvi reciprocamente, </strong> quando vi aspettano delle sfide.<br>Vi auguriamo, che in questa situazione della gravidanza possiate <strong>crescere nell\'unità</strong> – e sperimentare, quali cose meravigliose possono nacere da un\'amore profondo, che vede l\'altra persona per prima.</p>',
        1: '<p><strong>La tua capacità nr. 4: Relazione genitoriale</strong><br>A volte fa bene sapere che i propri genitori A volte è bello sapere che i tuoi genitori che ti sostengono - anche se sei già adulto! Puoi contare sulla ricchezza della loro esperienza. Possono offrirti <strong>un sostegno prezioso </strong>ed essere dei <strong>consiglieri</strong>.<br>Inoltre, soprattutto nella situazione momentanea, la vostra relazione può assumere una profondità completamente nuova. Loro come genitori, conoscono i diversi sentimenti e pensieri che si provano quando si annuncia la nascita di un bambino.</p>',
        2: '<p><strong>La tua capacità nr. 4: Legami familiari</strong><br>La famiglia ed i parenti creano una rete, che prorio adesso, in questo sconvolgimento - situazione, ti possono dare <strong>un appiglio sicuro</strong>. Queste Persone, che ti conoscono da una vita, stanno sicuramente già al tuo fianco - con aiuti pratici ed una comunicazione da cuore a cuore.<br>Ti auguriamo, che il tuo ambiente ti dia la forza necessaria per poter trovare il tuo percorso con uno sguardo aperto verso il futuro!</p>',
        3: '<p><strong>La tua capacità nr. 4: Cerchia di amici</strong><br>„Gli amici fedeli ballano con te sotto la pioggia invece di aspettare che il sole splenda.“ Questo detto si adatta bene alla tua situazione. Tu sai che i tuoi amici rimangono al tuo fianco.<br>Con dei compagni così fedeli, anche l\'escursione più impegnativa diventa un\'avventura e un\'opportunità per crescere insieme.</p>',
        4: '<p><strong>La tua capacità nr. 4: Cercare-aiuto</strong><br>È bello che non ti fermi  davanti alle tue preoccupazioni, ma cerchi attivamente una buona strada e ti sei imbattuta in noi. Saremo liete di essere a tua disposizione, di stare al vostro fianco e di cercare una buona soluzione attraverso le nostre informazioni ed i nostri articoli. Non sei da sola!<br>Per ulteriori informazioni o per ogni tipo di domande puoi volentieri visitare <a href="https://www.profemina.org/it-it/">la nostra homepage</a>.</p>',
      },
    },
    4: {
      question: 'Ecco come ho affrontato i problemi in passato:',
      choices: {
        0: 'Ho cercato di vedere il lato positivo in quella situazione',
        1: 'Non ho mai perso di vista il mio obiettivo',
        2: 'Mi sono fatta aiutare dagli altri',
        3: 'Ho riflettuto con calma',
        4: 'Attraverso la mia fiducia di base nella vita',
        5: 'Non ho avuto una formula particolare, ma in qualche modo ha sempre funzionato',
      },
      advices: {
        0: '<p><strong>La tua capacità nr. 5: Ottimismo</strong><br>Tu riesci a ballare sotto la pioggia invece di aspettare che il sole splenda.<br>Questa <strong>visione alacre e positiva della vita</strong> è qualcosa di speciale e prezioso! Soprattutto nell\'attuale situazione di incertezza, questo atteggiamento di base può darti coraggio. Perché ritieni possibile che si possa trovare qualcosa di buono in tutto ciò - anche se è ancora nascosto.<br>Ti auguriamo che con questo "sguardo del cuore" tu possa anche ora tirare fuori il meglio della tua vita.</p>',
        1: '<p><strong>La tua capacità nr. 5: Resistenza</strong><br>Avere un obiettivo ti dà coraggio e forza per <strong>guardare avanti</strong> e affrontare le sfide della vita. Quindi probabilmente hai già fatto l\'esperienza che potresti anche uscire rafforzata da situazioni difficili.<br>In questo momento, questo può aprire la strada a una soluzione sostenibile per i problemi con la tua gravidanza. Perché puoi darti un obiettivo - uno con cui puoi crescere e attraverso il quale puoi trasformare questa situazione in qualcosa di buono.</p>',
        2: '<p><strong>La tua capacità nr. 5: Sincerità</strong><br>Sai quanto è vero il detto "un dolore condiviso è un dolore dimezzato". Ti fa bene condividere le tue preoccupazioni e i tuoi pensieri con altre persone fidate, con cui ti confidi  da pari a pari . Così facendo, la tua vita interiore sarà riordinata e potrai scoprire prospettive completamente nuove.<br>Hai capito che essere sostenuta da altri non è una debolezza, ma una forza!<br>Soprattutto nella situazione presente, speriamo che tu possa accettare buoni consigli e un buon supporto! Questo può anche aprire la strada a un valido approccio per affrontare i problemi della gravidanza nell\'attuale contesto.</p>',
        3: '<p><strong>La tua capacità nr. 5: Coolness</strong><br>I momenti di silenzio ti danno forza - soprattutto in situazioni difficili in cui i pensieri e i sentimenti ti piombano addosso. In questo modo, il tuo turbamento interiore può gradualmente ritrovare il suo ordine; in questo modo, le sensazioni possono essere ricollocate nel giusto ordine di importanza.<br>Questi momenti di calma possono anche aprire la strada a buone soluzioni in questa gravidanza. In questo modo puoi arrivare al profondo del tuo essere  e scoprire quale opportunità potrebbe essere nascosta nell\'attuale sfida.</p>',
        4: '<p><strong>La tua capacità nr. 5: Fiducia</strong><br>La fiducia che anche i momenti difficili hanno il loro significato e che anche le situazioni difficili possono finire bene sembra percorrere tutta la tua vita. Nonostante le esperienze difficili hai mantenuto la fiducia nella vita. Probabilmente hai già sperimentato che anche <strong>nelle grandi sfide</strong> ci può essere nascosto qualcosa di buono.<br> In questo momento questa verità può aiutarti a rimanere fiduciosa. Questo ti dà la possibilità di trarre il meglio dalla situazione attuale.</p>',
        5: '<p><strong>La tua capacità nr. 5: Maestra nell\'arte del vivere</strong><br>È evidente che hai forza e coraggio dentro di te! Senza sapere esattamente cosa ti dà forza di volta in volta, hai sempre padroneggiato le sfide che la vita ti ha presentato. Altrimenti non sareste arrivata così lontano.<br>Proprio ora puoi trarre nuova fiducia da questo fatto, che sarai in grado  anche questa volta di risolvere i problemi attuali con la gravidanza. E che ancora una volta puoi superare te stessa.</p>',
      },
    },
  },
};
