export default {
  formSteps: {
    0: {
      question: 'Cosa ti aiuterebbe di più in questo momento?',
      choices: {
        0: 'Vorrei riordinare i miei pensieri e ricevere consiglio',
        1: 'Vorrei informazioni sulle mie opzioni',
        2: 'Vorrei qualcuno che mi stia vicino proprio ora',
      },
      advices: {
        0: '<p><strong>Riordinare i pensieri</strong><br>Probabilmente la situazione della gravidanza (non pianificata) ti ha causato più una <strong>confusione mentale</strong> e una certa <strong>perplessità.</strong> E questo è totalmente comprensibile! La gravidanza porta con sé molti cambiamenti, e molto rapidamente anche insicurezze e paure.<br>Speriamo che questa consulenza digitale possa aiutarti, <strong>per vedere con più chiarezza.</strong> E naturalmente sarebbe bello se alcuni dei consigli possono essere di aiuto per il tuo ulteriore percorso.</p>',
        1: '<p><strong>Informazioni su tutte le opzioni</strong><br>È sempre saggio essere pienamente informate quando si sta per prendere una decisione. Allo stesso tempo, le scadenze legali per un aborto o le possibili opzioni di sostegno per le donne incinte possono all\'inizio sembrare complicate. <br>Saremmo liete di poterti aiutare nell\'ambito della consulenza digitale, <strong>per considerare tutte le opzioni.</strong>  Perché allora, secondo la nostra esperienza, la maggior parte delle donne si sente <strong>più libera e meglio attrezzata per prendere una decisione.</strong></p>',
        2: '<p><strong>Qualcuno che mi sta vicino </strong><br>Una situazione come una gravidanza non pianificata o problematica può spingerti rapidamente ai tuoi limiti personali. Probabilmente ti senti allo stesso modo. Pertanto, il tuo desiderio di avere persone che ti sostengano è molto comprensibile. <br>Oltre a questa consulenza digitale, puoi informarti <strong>di più sulla nostra homepage di <i>profemina</i></strong>.Così puoi anche scoprire dove ci sono persone e istituzioni nella tua zona che possono aiutarti personalmente. Ma possiamo già assicurarti la cosa più importante: <strong>Non devi rimanere sola in questa situazione!</strong></p>',
      },
    },
    1: {
      question: 'Sono nella ... settimana di gravidanza.',
      choices: {
        0: 'Non lo so ancora',
        1: 'Prima dell\' 8a (da 0 a 7 sett+6 giorni)',
        2: 'Dalla 8a alla 14a settimana',
        3: 'Oltre la 14a settimana',
      },
      advices: {
        0: '<p><strong>La settimana di gravidanza</strong><br>Probabilmente hai scoperto solo di recente la tua gravidanza. Forse hai già preso un appuntamento con il tuo ginecologo, o stai per farlo. Lui può scoprire a che punto sei veramente e se la gravidanza è intatta. <br>Ora hai abbastanza tempo per prendere una decisione. Così puoi, finché non ne saprai di più, <strong>fare prima un respiro profondo.</strong> Ripensandoci, questo è esattamente ciò che giova molto alla maggior parte delle donne. È proprio per questo che ti consigliamo di <strong>non farti mettere sotto pressione.</strong></p>',
        1: '<p><strong>La settimana di gravidanza</strong><br>Sei ancora abbastanza agli inizi della tua gravidanza. Questo significa che  <strong>hai ancora un pò di tempo a disposizione</strong> per prendere una decisione. Quindi per il momento puoi fare un respiro profondo. <br>In retrospettiva, è proprio questo prendere fiato e darsi un pò di tempo che fa molto bene alla maggior parte delle donne. È proprio per questo che ti consigliamo di <strong>non farti mettere sotto pressione.</strong></p>',
        2: '<p><strong>La settimana di gravidanza</strong><br>Hai già vissuto alcune settimane di gravidanza.<br>Forse sei già vicina alla fine del termine per decidere sull\'aborto e ora senti che devi decidere entro pochi giorni o addirittura ore? È consigliabile concederti più tempo finché hai ancora dubbi e incertezze.<strong>Tu sei degna di cercare una decisione,</strong> che puoi sostenere con tutto il cuore.</p>',
        3: '<p><strong>La settimana di gravidanza</strong><br>Il primo trimestre della gravidanza è già alle tue spalle. Ma sembri ancora insicura su come le cose possano continuare per te. Quindi è un bene che tu abbia iniziato a cercare delle soluzioni!<br>Forse un aborto non è più possibile nel tuo caso. In Italia, l\'aborto è possibile entro 90 giorni dall\'ultima mestruazione o fino alla 12a settimana di garvidanza.<br> Forse questa notizia ti rassicura perché ti toglie la decisione "aborto - sì o no", per così dire. Ma forse è anche per questo che sei molto preoccupata. In ogni caso, speriamo che troverai qui degli impulsi che ti aiuteranno nella tua situazione.</p>',
      },
    },
    2: {
      question: 'Quanto determina la gravidanza la tua vita quotidiana?',
      choices: {
        0: 'Cerco di allontanare più che altro i pensieri sulla mia gravidanza.',
        1: 'Non riesco a smettere di pensare al fatto che sono incinta.',
        2: 'Ci penso, ma trovo anche lo spazio per altri pensieri.',
      },
      advices: {
        0: '<p><strong>Pensieri - come  può continuare?</strong><br>Finora hai cercato di non pensare troppo alla gravidanza. È comprensibile e importante che tu non lasci che i pensieri e le preoccupazioni ti controllino completamente.<br> Allo stesso tempo, è anche un bene che ora tu stia pensando in modo sano a come le cose possano continuare e che tu stia cercando delle buone soluzioni.</p>',
        1: '<p><strong>Pensieri - come  può continuare?</strong><br>Al momento, la tua gravidanza ti tiene molto occupata e difficilmente riesci a trovare pace tra i tanti pensieri e preoccupazioni. Probabilmente è estenuante e faticoso per te.<br>Fondamentalmente è ovviamente importante pensarci. Per prendere una buona decisione con <strong>una visione chiara,</strong> aiuta anche scendere <strong>consapevolmente dalla giostra mentale</strong> e staccare per un pò. Forse sarai in grado di prendere queste pause dai tuoi pensieri per fare un respiro profondo.</p>',
        2: '<p><strong>Pensieri - come  può continuare?</strong><br>Hai trovato un buon equilibrio nel modo in cui puoi affrontare mentalmente la gravidanza e tutto ciò che ti coinvolge. Pensi a ciò che è importante e naturale. Ma esci anche consapevolmente dalla giostra mentale <strong>per prendere fiato e avere una visione chiara.</strong><br> Non tutti ci riescono; e questo ti aiuterà a prendere una decisione matura.</p>',
      },
    },
    3: {
      question: 'La mia prima reazione alla gravidanza:',
      choices: {
        0: 'Paura',
        1: 'Shock',
        2: 'Felicitá',
        3: 'Caos di emozioni',
      },
      advices: {
        0: '<p><strong>Prima reazione: paura</strong><br>All\'inizio, la notizia della tua gravidanza sembra essersi posata su di te come un <strong>velo oscuro</strong>. Questi sentimenti e pensieri vanno assolutamente bene e molte donne si sentono così!<br>È un processo affinché tu possa sollevare un pò il velo e <strong>vedere di nuovo più chiaramente.</strong>  Non bisogna sottovalutare i cambiamenti ormonali all\'inizio della gravidanza. Perché la <strong>percezione del corpo in cambiamento </strong> può essere molto impegnativo per te, e lo senti anche a livello emotivo. Di solito peròs si attenua dopo qualche settimana.</p>',
        1: '<p><strong>Prima reazione: shock</strong><br>La notizia della tua gravidanza sembra averti davvero travolta e lasciata sotto shock. Questo succede a molte donne e non è insolito di fronte a una notizia così grande.<br>Oltre alla sorpresa, il  <strong>cambiamento ormonale</strong> durante l\'inizio della gravidanza probabilmente capovolge alcune cose a livello emotivo; questo non deve essere sottovalutato. Il <strong>cambiamento della percezione del proprio corpo</strong>può essere abbastanza fastidioso, e lo si sente anche emotivamente. Tuttavia, questo di solito si attenua dopo qualche settimana.</p>',
        2: '<p><strong>Prima reazione: felicità</strong><br>E bello che tu abbia potuto gioire della gravidanza in quel momento! Anche se nel frattempo sono sorte alcune preoccupazioni, la prima reazione parla già da sé. All\'inizio, probabilmente hai percepito i tuoi sentimenti senza il filtro delle tue domande e delle tue paure.<br>È consigliabile dare spazio anche a questa parte di te - e pensarci di tanto in tanto: Cos\'è che mi ha reso così felice all\'inizio?</p>',
        3: '<p><strong>Prima reazione: caos di emozioni</strong><br>È abbastanza normale che all\'inizio tu abbia avuto molti pensieri e sentimenti differenti. All\'inizio di una gravidanza, il  <strong>cambiamento ormonale</strong> e i cambiamenti del fisco sono spesso abbastanza evidenti a livello emotivo; questo non deve essere sottovalutato.<br>Ci vuole tempo perché il tuo corpo e la tua mente si confrontino con la situazione e si adattino ad essa. In questa fase, i tuoi sentimenti possono anche essere confusi ed incostanti. Dopo alcune settimane, tuttavia,  <strong>la calma interiore di solito ritorna,</strong> e in questo modo, con il tempo, molte cose possono sistemarsi di nuovo sia fisicamente sia che emotivamente.</p>',
      },
    },
    4: {
      question: 'Quale potrebbe essere il mio prossimo passo?',
      choices: {
        0: 'Vorrei parlare con una persona neutrale.',
        1: 'Vorrei confidarmi con una persona cara.',
        2: 'Non so ancora cosa fare.',
        3: 'Sto pensando di fare passi concreti verso l\'aborto.',
      },
      advices: {
        0: '<p><strong>Dopo: Persona di contatto neutrale</strong><br>Specialmente in una situazione in cui molte cose sono emotivamente confuse, spesso è bene avere una persona di contatto neutrale che conosce la situazione. La vista dall\'esterno può permettere delle <strong>prospettive completamente nuove</strong> sulla situazione.<br> Se stai <strong>ancora cercando</strong>  una tale persona di riferimento, pensiamo che i nostri articoli sulla mostra homepage potrebbero aiutarti, dato che sono scritti da consulenti esperte. <a href="https://www.profemina.org/it-it/home">Alla nostra homepage</a></p>',
        1: '<p><strong>Dopo: Conversazione con una persona cara</strong><br>Forse stai pensando al tuo partner, a tua madre o a una cara amica - in ogni caso, soprattutto in una situazione eccezionale, è bene confidarsi con qualcuno con cui puoi <strong>lasciarti andare</strong> e di cui  <strong>ti fidi.</strong><br>Ci auguriamo che tu possa parlare  <strong>apertamente e liberamente </strong> di ciò che ti muove e che tu sia accompagnata  <strong>nel cammino che è giusto per te.</strong></p>',
        2: '<p><strong>Dopo: Nessuna idea?!</strong><br>Al momento non vedi nessun passo successivo che possa aiutarti. Potrebbe farti bene parlare con una persona a te cara? O ti aiuterebbe avere informazioni più dettagliate dal tuo ginecologo sullo stato attuale della tua gravidanza?<br>Se vuoi informarti di più, non esitare a leggere i nostri articoli, scritti dalle nostre consulenti esperte! <a href="https://www.profemina.org/it-it/home">Alla nostra homepage</a></p>',
        3: '<p><strong>I prossimi passi</strong><br>Al momento, l\'aborto sembra essere la via più ovvia per te e stai pianificando i prossimi passi in questa direzione.<br>Forse non sei ancora completamente sicura - e allo stesso tempo sei ancora aperta a soluzioni "con bambino". È bene che continui a prestare attenzione a tutto ciò che succede dentro di te. Fino alla fine, hai la libertà di ascoltare le voci interiori più silenziose dentro di te e di includerle nella tua decisione.</p>',
      },
    },
    5: {
      question: 'Quale di queste immagini sceglieresti per trovare pace nella tua situazione attuale?',
      choices: {
        0: 'Vedere la luce alla fine del tunnel',
        1: 'Stare a casa sul mio morbido divano dove posso essere me stessa',
        2: 'Essere nella natura e respirare in libertà',
        3: 'Trovarsi sulla cima di una montagna e poter riconoscere tutto dall\'alto',
        4: 'Avere delle persone adorabili intorno a me che mi stanno vicino',
      },
      advices: {
        0: '<p><strong>Luce alla fine del tunnel</strong><br>Il peso delle tue preoccupazioni per la gravidanza ti pesa abbastanza. Se una luce brillasse in questa situazione e tu sentissi che questa fase oscura può anche finire, potresti fare un respiro profondo e trovare nuovamente coraggio.<br> Puoi quindi chiederti: cosa sarebbe per me concretamente una luce in questa situazione? Dove vedo già un tale piccolo spiraglio di luce? E cosa potrei fare perché questi "momenti di luce" si moltiplichino?</p>',
        1: '<p><strong>Il mio luogo di pace: a casa sul divano</strong><br>La gravidanza al momento è qualcosa di sconosciuto e nuovo per te , e questo ti fa sentire insicura- questo rientra nella natura delle cose. Sarebbe un sollievo per te se tu potessi trovare un posto sicuro e protetto. Da lì, molte cose sono più facili da affrontare.<br>Puoi quindi chiederti: qual è un tale posto sicuro per me? Dove o con chi vivo adesso momenti in cui ho questa sensazione di sicurezza? E cosa potrei fare concretamente per vivere adesso più spesso questi momenti?</p>',
        2: '<p><strong>Respirare profondamente nella natura</strong><br>La gravidanza scatena in te una sensazione di non poter respirare liberamente e di essere forse un pò rinchiusa nelle tue circostanze. Uscire nella natura e semplicemente respirare profondamente ti darebbe sollievo e probabilmente aprirebbe la tua mente.<br>In questa situazione, puoi uscire più spesso nella natura per ottenere questa sensazione e la distanza necessaria? Dove altro sperimenti questi momenti di respiro profondo nella vita quotidiana? E cosa si può fare per vivere più spesso questi momenti?</p>',
        3: '<p><strong>Sulla vetta della montagna – Prospettiva aerea</strong><br>Probabilmente ti senti intrappolata nell\' attuale situazione. È difficile per te mantenere la tua prospettiva in mezzo a tutte le preoccupazioni e guardare la situazione da una certa prospettiva aerea. Ma è proprio questo che potrebbe ora portarti sollievo.<br> Ci sono momenti attuali in cui sperimenti questa prospettiva dall\'alto e questa vista libera? Oppure persone che ti aiutano in questo? E cosa potresti fare concretamente per vivere adesso più spesso questi momenti?</p>',
        4: '<p><strong>Tra persone care</strong><br>La gravidanza e le preoccupazioni ti appesantiscono ed hai la sensazione di sentirti sola. Ma ti aiuterebbe avere persone amorevoli intorno a te per acquistare fiducia e forse forza e coraggio.<br>Chi sono queste persone amorevoli che ti edificano? Cosa potresti fare concretamente per mantenere più contatti con queste persone in questo momento?</p>',
      },
    },
    6: {
      question: 'Per il futuro desidero:',
      choices: {
        0: 'Che io possa conservare i lati belli della mia vita.',
        1: 'Che possa prendere una decisione che mi farà stare bene a lungo.',
        2: 'Che io possa provare gioia per la mia gravidanza.',
      },
      advices: {
        0: '<p><strong>Il bello della vita</strong><br>Un desiderio assolutamente comprensibile: quello di non voler perdere le cose belle della vita a causa della tua decisione. Questo è ciò che rende la vita ricca e degna di essere vissuta.<br> Quali sono esattamente gli aspetti belli della tua vita e come puoi preservarli? Da questa situazione potrebbe emergere una bellezza ancora maggiore e diversa da quella che hai conosciuto finora?</p>',
        1: '<p><strong>Una buona decisione</strong><br>Tutti conoscono situazioni nella vita in cui ci si trova di fronte a un bivio. Ed è comprensibile che non si voglia rimpiangere in seguito la decisione presa - ma che si possa guardare indietro con <strong>orgoglio</strong>. Allo stesso tempo, il percorso presumibilmente più facile non è sempre automaticamente quello giusto.<br> Forse sei già abbastanza sicura che arriverai presto a una buona decisione. Forse vorresti qualche <strong>suggerimento da una fonte indipendente</strong> – allora il nostro <a href="https://www.profemina.org/it-it/aborto/test-daborto">test d\'aborto</a> potrebbe esserti d\'aiuto.</p>',
        2: '<p><strong>Gioia per la gravidanza</strong><br>Forse hai provato una tenera gioia all\'inizio. Ma ora ci sono anche altri sentimenti, e questo è lecito. I sentimenti più critici mostrano anche che sei <strong> consapevole della tua responsabilità</strong> e che quindi ci stai pensando molto.<br>Alla fine,<strong> non è solo quello che si sente che conta</strong> (spesso non abbiamo alcuna influenza su questo), ma soprattutto<strong> quello che se ne fa.</strong> I sentimenti poi di solito seguono il loro ritmo, una volta che è stata presa una decisione consapevole.</p>',
      },
    },
  },
};
