const finalQuestion = 'Quello che adesso mi aiuterebbe di più:';

const finalChoicesGeneral = {
  0: 'qualcuno che mi ascolti e mi stia vicino',
  1: 'avere tempo per me',
  2: 'avere un piano',
  3: 'che il mio partner dica "sì" al bambino',
  4: 'un sostegno pratico o economico',
};

// Pfad 1: Er will das Kind nicht/öui non vuole il bambino
// Pfad 8.5: Vergewaltigung/stupro
// Path 1: He doesn't want the child
// Path 8.5: Rape
const finalChoicesException = {
  0: 'qualcuno che mi ascolti e mi stia vicino',
  1: 'avere tempo per me',
  2: 'avere un piano',
  3: 'un sostegno pratico o economico',
};

const adviceSomebodyWhoListensToMe = '<p><strong>Come si può evolvere la situazione?</strong><br> C\'è forse <strong>una persona cara nel tuo ambiente</strong>, di cui ti fidi e con cui potresti parlare? Una persona dalla quale ti senti accolta?<br><br>Come sicuramente hai già intuito alla fine nessuno può decidere per te. Tuttavia, le persone possono sostenersi e accompagnarsi a vicenda. Spero di essere già stata in grado di darti un po\' di sostegno attraverso la consulenza digitale. Naturalmente, puoi anche andare sulla nostra homepage di <i>profemina</i> per ulteriori informazioni: <a href="https://www.profemina.org/it-it/home" target="_blank" style="color: #55855d"><i>profemina</i> homepage</a></p>';
const adviceTimeForMyself = '<p><strong>Come si può evolvere la situazione?</strong><br>Puoi prenderti in maniera consapevole il tempo che desideri per farti del bene e per sistemarti interiormente.<br><br>Come sicuramente hai già intuito, alla fine nessuno può prendere questa decisione per te. Ma le persone possono sostenersi e accompagnarsi a vicenda. Speriamo che questi consigli digitali ti abbiano già dato un po\' di sostegno. Naturalmente, puoi anche andare sulla nostra homepage di <i>profemina</i> per ulteriori informazioni: <a href="https://www.profemina.org/it-it/home" target="_blank" style="color: #55855d"><i>profemina</i> homepage</a></p>';
const advicePlan = '<p><strong>Come si può evolvere la situazione?</strong><br>A volte aiuta a mettere su carta i propri pensieri e sentimenti, per poter risolvere il problema. Puoi provare a fare un piccolo passo alla volta - non devi fare tutto in una volta. Può essere che dopo ogni passo si apra per te una nuova prospettiva da cui poi puoi continuare a pensare più a fondo.<br><br>Come sicuramente hai già intuito, alla fine nessuno può prendere questa decisione per te. Ma le persone possono sostenersi e accompagnarsi a vicenda. Spero di essere già stata in grado di darti un po\' di sostegno attraverso il servizio di consulenza digitale. Naturalmente, puoi anche andare sulla nostra homepage di <i>profemina</i> per ulteriori informazioni: <a href="https://www.profemina.org/it-it/home" target="_blank" style="color: #55855d"><i>profemina</i> homepage</a></p>';
const adviceSupport = '<p><strong>Come si può evolvere la situazione?</strong><br>Molte donne nella tua situazione cercano un sostegno concreto pratico o economico. Pertanto, ci sono sia sussidi statali che privati. Puoi trovarne alcuni sulla nostra homepage sotto "Cosa mi spetta in gravidanza". Ci sono forse anche delle persone nel tuo ambiente che possono esserti d\'aiuto e stare al tuo fianco?<br><br>Come sicuramente hai già intuito, alla fine nessuno può prendere per te questa decisione. Eppure le persone possono sostenersi e accompagnarsi a vicenda. Spero di essere già stata in grado di darti un po\' di sostegno attraverso la consulenza digitale. Naturalmente, puoi anche visitare la nostra homepage di <i>profemina</i> per avere ulteriori informazioni: <a href="https://www.profemina.org/it-it/home" target="_blank" style="color: #55855d"><i>profemina</i> homepage</a></p>';

const advicePartnerSaysYesGeneral = '<p><strong>Come si può evolvere la situazione?</strong><br>È comprensibile e naturale che ora tu desideri il sostegno del tuo partner!<br><br>L\'esperienza dimostra che all\'inizio molti uomini trovano difficile sentirsi coinvolti nella gravidanza. Nel primo momento, le preoccupazioni e i dubbi si trovano spesso in primo piano. Il futuro padre ha probabilmente bisogno di più tempo di te per accettare la nuova situazione a livello razionale e di cuore. Forse ti sembra inverosimile al momento, ma alla fine la maggior parte degli uomini accettano la loro responsabilità e diventano persino dei padri orgogliosi. Potrebbe aiutare entrambi se tu, in questo momento, ascoltassi te stesso e il tuo io interiore e cercassi la decisione più giusta per te.<br><br>Come sicuramente hai già intuito, alla fine nessuno può prendere per te questa decisione. Eppure le persone possono sostenersi e accompagnarsi a vicenda. Spero di essere già stata in grado di darti un po\' di sostegno attraverso la consulenza digitale. Naturalmente, puoi anche visitare la nostra homepage di <i>profemina</i> per avere ulteriori informazioni: <a href="https://www.profemina.org/it-it/home" target="_blank" style="color: #55855d"><i>profemina</i> homepage</a></p>';
// Spezialfall für Pfad 2: es zwischen uns kompliziert ist/tra di noi è complicato
// Special Case for Path 2: our relationship is complicated
const advicePartnerSaysYesTree211 = '<p><strong>Come si può evolvere la situazione?</strong><br>Tu hai specificato, che ciò che ti aiuterebbe di più in questo momento, sarebbe che il tuo (ex) compagno dicesse di sì al bambino. È comprensibile e naturale che tu desideri questo. E la grande maggioranza delle donne nella tua situazione desidera la stessa cosa!<br><br>L\'esperienza insegna che all\'inizio molti uomini hanno difficoltà ad accettare la gravidanza, soprattutto se la relazione è complicata. Nel primo momento, le preoccupazioni e i dubbi sono spesso in primo piano. Il futuro padre ha probabilmente bisogno di più tempo di te per accettare la nuova situazione con il cuore e la ragione. Forse ti sembra inverosimile al momento, ma la maggior parte degli uomini alla fine accettano la loro responsabilità e diventano persino dei padri orgogliosi. Potrebbe aiutare entrambi se, in questo momento,  tu ascoltassi te stessa e il tuo io interiore e cercassi la decisione più giusta per te.<br><br>Come sicuramente hai già intuito, nessuno può toglierti questa decisione. Eppure le persone possono sostenersi e accompagnarsi a vicenda. Spero di essere già stata in grado di darti un po\' di sostegno attraverso la consulenza digitali. Naturalmente, puoi anche visitare la nostra homepage di <i>profemina</i> per avere ulteriori informazioni: <a href="https://www.profemina.org/it-it/home" target="_blank" style="color: #55855d"><i>profemina</i> homepage</a></p>';
const advicePartnerSaysYesTree212 = '<p><strong>Come si può evolvere la situazione?</strong><br>Tu hai precisato che in questo momento ciò che ti aiuterebbe di più sarebbe che il tuo (ex) partner dicesse di sì al bambino. È comprensibile e naturale che tu desideri questo. E la grande maggioranza delle donne nella tua situazione desidera la stessa cosa!<br><br>L\'esperienza insegna che molti uomini trovano difficile all\'inizio accettare la gravidanza, soprattutto se la relazione è complicata. Nel primo momento, le preoccupazioni e i dubbi sono spesso in primo piano. Il futuro padre ha probabilmente bisogno di più tempo di te per accettare la nuova situazione con il cuore e la ragione. Forse ti sembra inverosimile al momento, ma la maggior parte degli uomini alla fine accettano la loro responsabilità e diventano persino dei padri orgogliosi. Potrebbe aiutare entrambi se tu, in questo momento, ascoltassi te stessa e il tuo io interiore e cercassi la decisione più giusta per te.<br><br>Come sicuramente hai già intuito, nessuno può prendere per te questa decisione. Ma le persone possono sostenersi e accompagnarsi a vicenda. Naturalmente, puoi anche visitare la nostra homepage di <i>profemina</i> per avere ulteriori informazioni: <a href="https://www.profemina.org/it-it/home" target="_blank" style="color: #55855d"><i>profemina</i> homepage</a></p>';
// Path 2.3.1 & 2.3.2
// das Kind ist von einem anderen/il bambino è di un altro
// the child is from someone else
const advicePartnerSaysYesTree23 = '<p><strong>Come si può evolvere la situazione?</strong><br>Tu hai precisato che in questo momento ti aiuterebbe di più sarebbe che il tuo partner dicesse "sì" al bambino. È comprensibile e naturale che tu voglia questo! L\'esperienza ha evidenziato che molti uomini, all\'inizio, hanno difficoltà ad accettare la gravidanza. Tanto più se il bambino è di un altro. Nel primo momento, preoccupazioni, delusioni e dubbi sono probabilmente in primo piano. Forse lui ha semplicemente bisogno di tempo per affrontare la nuova situazione a livello di cuore e di ragione. Potrebbe aiutare entrambi se tu ora ascoltassi te stessa e il tuo io interiore e cercassi la decisione più giusta per te. Questo gli darà lo spazio necessario per trovare il proprio percorso e forse finalmente stare dalla parte del bambino - questo succede in continuazione! Come sicuramente hai già intuito, nessuno può prendere per te questa decisione. Ma le persone possono però sostenersi e accompagnarsi a vicenda. Naturalmente, puoi anche visitare la nostra homepage di <i>profemina</i> per avere ulteriori informazioni: <a href="https://www.profemina.org/it-it/home" target="_blank" style="color: #55855d"><i>profemina</i> homepage</a></p>';

// Text Components / Duplications
const adviceTextBothWays = '<br><br><strong>Osservare i due percorsi</strong><br>In questo momento ti stai chiedendo: Aborto - sì o no? Qualunque cosa tu decida: Entrambe hanno un effetto sulla tua vita. Forse può aiutarti rispondere ad alcune domande, al fine di ampliare la tua visione e avvicinarti ad una decisione matura.<br>';
const adviceTextBothWaysIntro = 'Immagina che tu abbia già preso la tua decisione e che tu possa dire in seguito: è stata davvero una buona decisione!';

const adviceTextTree1BiggestConcernsOption1 = '<strong>La tua più grande preoccupazione</strong><br>Probabilmente la novità della gravidanza di per sé ti scombussola. Forse all\'inizio avevi immaginato l\'essere incinta in modo molto diverso. Se poi anche il padre del bambino fa dei commenti critici, questo può comprensibilmente farti arrivare ai tuoi limiti personali.';
const adviceTextTree1BiggestConcernsOption2 = '<strong>La tua più grande preoccupazione</strong><br>Probabilmente la novità della gravidanza di per sé ti scombussola. Forse all\'inizio avevi immaginato l\'essere incinta in modo molto diverso. Se poi ora anche il padre del bambino fa dei commenti critici, è comprensibile che questo scateni in te delle preoccupazioni.';

const adviceTextTree21BiggestConcern = '<strong>La tua più grande preoccupazione</strong><br>In realtà, tra voi due è già finita  - ma ora stai aspettando il suo bambino. A prima vista, sembra che non ci sia un momento peggiore per una gravidanza. Questo probabilmente ti sta facendo arrivare ai tuoi limiti personali...<br><br>L\'ultimo tempo non è stato sicuramente facile per te, perché forse le tensioni hanno condizionato per molto tempo la relazione. ';
const adviceTextTree22BiggestConcern = '<strong>La tua più grande preoccupazione</strong><br>L\'ultimo tempo non è stato sicuramente facile per te, perché forse le tensioni hanno condizionato per molto tempo la relazione.. Solo questo solleva già molte domande.<br><br>La sfida in più della gravidanza in questo momento ti sta probabilmente facendo arrivare ai tuoi limiti personali. ';
const adviceTextTree23BiggestConcern = '<strong>La tua più grande preoccupazione</strong><br>Forse è da tempo che c\'era della tensione tra te e il tuo partner. Dopo tutto forse  hai cercato in un altro uomo quello che ti mancava.  Forse le cose stavano andando abbastanza bene tra di voi - tuttavia, sei finita in questa situazione con un altro uomo, che ora non può essere cancellata. Comunque siano andate le cose: ora sei (forse o sicuramente) incinta di un altro uomo.<br><br>All\'improvviso, tutto è diverso, e probabilmente sei mossa da tutti i tipi di pensieri, sentimenti, preoccupazioni e paure. È molto comprensibile che questa situazione ti stia facendo arrivare ai tuoi limiti personali.';

const adviceTextTree3BiggestConcern = '<strong>La tua più grande preoccupazione</strong><br>Ci sono molte ragioni diverse per cui un bambino può non sembrare opportuno in questo momento. Rimanere incinta nella situazione attuale può averti causato una grande confusione proprio perché non volevi avere un figlio "proprio così".<br><br>Forse è particolarmente difficile per te perché vuoi essere all\'altezza del tuo bambino ed essere lì per lui/lei, al suo fianco quando entra nella tua vita. In realtà, è questo che fa il cuore di una madre: vuole il meglio per suo figlio. Pertanto, è comprensibile che la situazione attuale sia per te un vero calvario !<br><br>';

const adviceTextBullet1 = '<li>Cos\'è che generalmente ti dà forza nei momenti di incertezza, dove puoi fare ora rifornimento di energia?</li>';
const adviceTextBullet2 = '<li>Quando più tardi ripenserai alla tua decisione e saprai che era buona e giusta, come riconoscerai che era giusta? Di cosa saresti particolarmente orgogliosa?</li>';
const adviceTextBullet3 = '<li>Come sapresti che la decisione è stata buona?</li><li>Di cosa saresti particolarmente orgogliosa?</li>';

export {
  finalQuestion,
  finalChoicesGeneral,
  finalChoicesException,
  adviceSomebodyWhoListensToMe,
  adviceTimeForMyself,
  advicePlan,
  adviceSupport,
  advicePartnerSaysYesGeneral,
  advicePartnerSaysYesTree211,
  advicePartnerSaysYesTree212,
  advicePartnerSaysYesTree23,
  adviceTextBothWays,
  adviceTextBothWaysIntro,
  adviceTextTree1BiggestConcernsOption1,
  adviceTextTree1BiggestConcernsOption2,
  adviceTextTree21BiggestConcern,
  adviceTextTree22BiggestConcern,
  adviceTextTree23BiggestConcern,
  adviceTextTree3BiggestConcern,
  adviceTextBullet1,
  adviceTextBullet2,
  adviceTextBullet3,
};
